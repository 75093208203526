import React from "react"
import { graphql } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import useMetadata from "../../hooks/useMetadata"
import Layout from "../../components/Layout"
import PropTypes from "prop-types"

const JobOpening = ({ data, location }) => {
  let pathname = ""
  if (location) {
    pathname = location.pathname
  }
  const { contentfulJobOpening: jobOpening } = data
  const {
    title,
    slug,
    excerpt: { excerpt },
    jobDescription: { jobDescription },
    shortDescription: { shortDescription },
    jobRequirement: { jobRequirement },
    benefits: { benefits },
    others: { others },
    image: { gatsbyImageData },
    seo: { metaTitle },
  } = jobOpening

  const { siteUrl } = useMetadata()
  const canonical = `${siteUrl}/join-us/${slug}`

  const SEO = () => {
    return (
      <GatsbySeo
        title={title}
        description={shortDescription}
        canonical={canonical}
        openGraph={{
          url: canonical,
          title: title || metaTitle,
          description: shortDescription,
          images: [
            {
              url: `https:${gatsbyImageData.images.fallback.src}`,
              width: 800,
              height: 600,
              alt: title || metaTitle,
            },
          ],
          type: "website",
          locale: "zh_tw",
        }}
      />
    )
  }

  return (
    <>
      <Layout transParentMenu={false} pathname={pathname}>
        <SEO />
        <div className="relative mt-nav">
          <GatsbyImage
            image={gatsbyImageData}
            alt={title}
            className={"h-48 sm:h-64 w-full"}
          />

          <div className="absolute flex justify-center items-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-center w-full h-full job-title">
            <h1 className="text-3xl sm:text-4xl tracking-wider font-heavy text-white title-border">
              {title}
            </h1>
          </div>
        </div>

        <div className="container py-16 lg:py-24 flex">
          <div className="block sm:hidden mr-3">
            <StaticImage
              src={"../../images/img_quote_mobile.svg"}
              alt={"quote"}
              width={24}
              height={20}
              placeholder={"blurred"}
              className="w-6 h-5"
            />
          </div>
          <div className="hidden sm:block mr-12">
            <StaticImage
              src={"../../images/img_quote.svg"}
              alt={"quote"}
              width={64}
              height={53}
              placeholder={"blurred"}
              className="w-16 h-auto"
            />
          </div>

          <ReactMarkdown
            className="text-gray-500 lg:text-xl"
            rehypePlugins={[rehypeRaw]}
          >
            {excerpt}
          </ReactMarkdown>
        </div>

        <div className="bg-gray-200 text-gray-700">
          <div className="sm:container py-16 lg:py-24 px-6 sm:px-8 mx-auto space-y-16 lg:space-y-24">
            <div className="space-y-6 sm:space-y-0 text-center sm:text-left sm:grid sm:grid-cols-4">
              <div className="sm:col-span-1">
                <h2 className="title-border text-2xl lg:text-3xl tracking-wider font-heavy">
                  工作內容
                </h2>
              </div>
              <ReactMarkdown className="text-left job-description leading-loose sm:col-span-3">
                {jobDescription}
              </ReactMarkdown>
            </div>
            <div className="space-y-6 sm:space-y-0 text-center sm:text-left sm:grid sm:grid-cols-4">
              <div className="sm:col-span-1">
                <h2 className="title-border text-2xl lg:text-3xl tracking-wider font-heavy">
                  條件需求
                </h2>
              </div>
              <ReactMarkdown className="text-left job-description leading-loose sm:col-span-3">
                {jobRequirement}
              </ReactMarkdown>
            </div>
            <div className="space-y-6 sm:space-y-0 text-center sm:text-left sm:grid sm:grid-cols-4">
              <div className="sm:col-span-1">
                <h2 className="title-border text-2xl lg:text-3xl tracking-wider font-heavy">
                  薪資福利
                </h2>
              </div>
              <ReactMarkdown className="text-left job-description leading-loose sm:col-span-3">
                {benefits}
              </ReactMarkdown>
            </div>
            <div className="space-y-6 sm:space-y-0 text-center sm:text-left sm:grid sm:grid-cols-4">
              <div className="sm:col-span-1">
                <h2 className="title-border text-2xl lg:text-3xl tracking-wider font-heavy">
                  其他
                </h2>
              </div>
              <ReactMarkdown className="text-left job-description leading-loose sm:col-span-3">
                {others}
              </ReactMarkdown>
            </div>
          </div>
        </div>
        <div className="container text-center space-y-6 lg:space-y-12 text-gray-700 py-16 lg:py-24">
          <h2 className="title-border text-2xl lg:text-4xl tracking-wider font-heavy">
            應徵方式
          </h2>
          <p className="text-left lg:text-xl">
            請將履歷與相關資料寄至
            <span className="font-bold"> hr@zhelu.tw</span>
            ，並註明【{title}
            ，姓名OOO，手機號碼XXXX-XXX-XXX】，感謝您，期待您的加入!
          </p>
        </div>
      </Layout>
    </>
  )
}

JobOpening.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default JobOpening

export const pageQuery = graphql`
  query JobOpening($slug: String!) {
    contentfulJobOpening(slug: { eq: $slug }) {
      title
      slug
      excerpt {
        excerpt
      }
      jobDescription {
        jobDescription
      }
      jobRequirement {
        jobRequirement
      }
      benefits {
        benefits
      }
      others {
        others
      }
      image {
        gatsbyImageData(quality: 100, placeholder: BLURRED)
      }
      seo {
        metaTitle
        metaDescription
      }
      shortDescription {
        shortDescription
      }
    }
  }
`
